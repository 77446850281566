<template>
  <layout-empty>
    <base-customer-overview :customers="getCustomers" />
  </layout-empty>
</template>

<script>
import { mapGetters } from "vuex";
import BaseCustomerOverview from "@components/base/BaseCustomerOverview";

export default {
  name: "ServicePage",
  components: { BaseCustomerOverview },
  computed: {
    ...mapGetters("service", ["getCustomers"]),
  },
};
</script>

<style scoped></style>
